import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import moment from 'moment';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ptBR from 'antd/lib/locale/pt_BR';

import Theme from 'styles/theme';
import Signalr from 'components/BusinessLogic/Signalr';
import App from './App';
import i18n from './i18n';
import { store } from './store/store';

import 'moment/locale/pt-br';

import GlobalStyle from './styles/GlobalStyle';

moment().isoWeek();
moment.updateLocale('pt-br', {
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
moment.locale('pt-br');

ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={Theme}>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider locale={ptBR}>
          <BrowserRouter>
            <Signalr />
            <App />
          </BrowserRouter>
          <GlobalStyle />
        </ConfigProvider>
      </I18nextProvider>
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root'),
);
