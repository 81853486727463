import { FormInstance } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import produce from 'immer';
import AutoCategory from 'model/ServiceRequests/AutoCategory';
import Category from 'model/ServiceRequests/Category';
import SubCategory from 'model/ServiceRequests/SubCategory';
import {
  ICreateServiceRequestParams,
  IFetchParams,
  ISendAttachmentFileParams,
  IServiceRequestResponse,
  checkIfDeskManagerUserIsValid,
  createServiceRequest,
  getServiceRequestsAutoCategories,
  getServiceRequestsCategories,
  getServiceRequestsSubCategories,
} from 'services/serviceRequestsService';
import { create } from 'zustand';

export type IActions = {
  setData: (fn: (state: IServiceRequests) => void) => void;
  setFormInstance: (form: Partial<FormInstance<IFormFilter>>) => void;
  getFilters: () => Promise<void>;
  getCategories: () => void;
  getSubCategories: () => void;
  getAutoCategories: () => void;
  isEmailValid: (email: string) => Promise<boolean>;
  validateEmailAndLoadFilters: (email: string) => void;
  sendAttachmentFile: (file: UploadChangeParam<any>) => void;
  createServiceRequest: () => Promise<IServiceRequestResponse>;
  resetFilters: () => void;
  clearAttachmentFile: () => void;
};

export type ISelectors = {
  isFiltersSuccessful: () => boolean;
};

export type IFormFilter = {
  customerAccountCode: number | null;
  categoryId: number | null;
  subCategoryId: number | null;
  autoCategoryId: number | null;
  description: string | null;
  menuOrPage: string | null;
  completeName: string | null;
  periodDate: any[];
};

export type IFilters = {
  categories: {
    data: Category[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  subCategories: {
    data: SubCategory[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  autoCategories: {
    data: AutoCategory[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
    isFailure: boolean;
    isReports: boolean;
  };
  attachments: {
    data: any[];
    info: ISendAttachmentFileParams;
    isLoading: boolean;
  };
  email: {
    data: string;
    isValid: boolean;
  };

  formFilter: IFormFilter;
};

export type IServiceRequests = {
  filters: IFilters;
  formFilterInstance: Partial<FormInstance<IFormFilter>> | null;
} & IActions &
  ISelectors;

export const initialStateFilters: IFilters = {
  categories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: '',
  },
  subCategories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: '',
  },
  autoCategories: {
    data: [],
    isLoading: false,
    isSuccessful: false,
    isError: false,
    errorMessage: '',
    isFailure: false,
    isReports: false,
  },
  attachments: {
    data: [],
    info: {
      Base64: '',
      FileName: '',
      FileSize: '',
    },
    isLoading: false,
  },
  email: {
    data: '',
    isValid: false,
  },

  formFilter: {
    customerAccountCode: null,
    categoryId: null,
    subCategoryId: null,
    autoCategoryId: null,
    description: null,
    menuOrPage: null,
    completeName: null,
    periodDate: [],
  },
};

const useServiceRequestsStore = create<IServiceRequests>()((set, get) => ({
  // States
  filters: initialStateFilters,
  formFilterInstance: null,

  // Setters
  setData: (fn) => set(produce(fn)),
  setFormInstance: (form: Partial<FormInstance<IFormFilter>>) => {
    set({ formFilterInstance: form });
  },

  // Selectors
  isFiltersSuccessful: () => {
    const { categories, subCategories, autoCategories } = get().filters;
    return (
      categories.isSuccessful &&
      subCategories.isSuccessful &&
      autoCategories.isSuccessful
    );
  },

  // Actions
  getCategories: async () => {
    const { setData } = get();

    setData((state) => {
      state.filters.categories.isLoading = true;
      state.filters.categories.isSuccessful = false;
      state.filters.categories.isError = false;
    });

    try {
      const params: IFetchParams = {
        Pesquisa: '',
        Ativo: 'S',
        isProducer: false,
      };

      const { data } = await getServiceRequestsCategories(params);

      setData((state) => {
        state.filters.categories.data = data;
        state.filters.categories.isSuccessful = true;
        state.filters.categories.isError = false;
      });
    } catch (error) {
      setData((state) => {
        state.filters.categories.data = [];
        state.filters.categories.isSuccessful = false;
        state.filters.categories.isError = true;
      });
    } finally {
      setData((state) => {
        state.filters.categories.isLoading = false;
      });
    }
  },
  getSubCategories: async () => {
    const { setData } = get();

    setData((state) => {
      state.filters.subCategories.isLoading = true;
      state.filters.subCategories.isSuccessful = false;
      state.filters.subCategories.isError = false;
    });

    try {
      const categoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('categoryId') ?? null;

      const categoryNameSelected =
        get().filters.categories.data.find(
          (i) => i.account === categoryIdSelected,
        )?.name || '';

      const params: IFetchParams = {
        Pesquisa: categoryNameSelected,
        Ativo: 'S',
        isProducer: false,
      };

      const { data } = await getServiceRequestsSubCategories(params);

      setData((state) => {
        state.filters.subCategories.data = data;
        state.filters.subCategories.isSuccessful = true;
        state.filters.subCategories.isError = false;
      });
    } catch (error) {
      setData((state) => {
        state.filters.subCategories.data = [];
        state.filters.subCategories.isSuccessful = false;
        state.filters.subCategories.isError = true;
      });
    } finally {
      setData((state) => {
        state.filters.subCategories.isLoading = false;
      });
    }
  },
  getAutoCategories: async () => {
    const { setData } = get();

    setData((state) => {
      state.filters.autoCategories.isLoading = true;
      state.filters.autoCategories.isSuccessful = false;
      state.filters.autoCategories.isError = false;
    });

    try {
      const categoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('categoryId') ?? null;
      const subCategoryIdSelected =
        get().formFilterInstance?.getFieldValue?.('subCategoryId') ?? null;

      const categoryNameSelected =
        get().filters.categories.data.find(
          (category) => category.account === categoryIdSelected,
        )?.name || '';
      const subCategoryNameSelected =
        get().filters.subCategories.data.find(
          (subCategory) => subCategory.account === subCategoryIdSelected,
        )?.name || '';

      const params: IFetchParams = {
        Pesquisa:
          categoryNameSelected && subCategoryNameSelected
            ? `${categoryNameSelected} - ${subCategoryNameSelected}`
            : categoryNameSelected || subCategoryNameSelected || '',
        Ativo: 'S',
        isProducer: false,
      };

      const { data } = await getServiceRequestsAutoCategories(params);

      setData((state) => {
        state.filters.autoCategories.data = data;
        state.filters.autoCategories.isSuccessful = true;
        state.filters.autoCategories.isError = false;
      });
    } catch (error) {
      setData((state) => {
        state.filters.autoCategories.data = [];
        state.filters.autoCategories.isSuccessful = false;
        state.filters.autoCategories.isError = true;
      });
    } finally {
      setData((state) => {
        state.filters.autoCategories.isLoading = false;
      });
    }
  },
  getFilters: async () => {
    const { getCategories, getSubCategories, getAutoCategories } = get();
    try {
      await Promise.all([
        getCategories(),
        getSubCategories(),
        getAutoCategories(),
      ]);
      return Promise.resolve();
    } catch (error) {
      console.error('Error ao carregar filtros.', error);
      return Promise.reject(error);
    }
  },
  resetFilters: () => {
    const { setData } = get();
    setData((state) => {
      state.filters = initialStateFilters;
    });
  },
  isEmailValid: async (email: string) => {
    try {
      const response = await checkIfDeskManagerUserIsValid(email);
      const isUserValid = response.data;

      if (!isUserValid || typeof isUserValid !== 'number') {
        return false;
      }
      return true;
    } catch (error) {
      console.error('Erro ao validar usuário.', error);
      return Promise.reject(error);
    }
  },
  validateEmailAndLoadFilters: async (email: string) => {
    const { setData, isEmailValid, getFilters } = get();
    const userExists = await isEmailValid(email);

    if (userExists) {
      getFilters();
      setData((state) => {
        state.filters.email.data = email;
        state.filters.email.isValid = true;
      });
    } else {
      setData((state) => {
        state.filters.email.isValid = false;
      });
    }
  },
  sendAttachmentFile: async (file: UploadChangeParam<UploadFile>) => {
    const { setData } = get();

    setData((state) => {
      state.filters.attachments.data = file.fileList.map((f) => {
        if (f.uid === file.file.uid) {
          return { ...f, status: 'uploading' };
        }
        return f;
      });
    });

    try {
      if (get().filters.attachments.data.length > 0) {
        const base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.file.originFileObj as Blob);
          reader.onload = () => {
            const base64String = (reader.result as string).split(',')[1];
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
        });

        const params: ISendAttachmentFileParams = {
          Base64: base64 as string,
          FileName: file.file.name,
          FileSize: String(file.file.size),
        };

        setData((state) => {
          state.filters.attachments.info = params;
        });
      } else {
        setData((state) => {
          state.filters.attachments.info = initialStateFilters.attachments.info;
        });
      }

      setData((state) => {
        state.filters.attachments.data = file.fileList.map((f) => {
          if (f.uid === file.file.uid) {
            return { ...f, status: 'done' };
          }
          return f;
        });
      });
    } catch (error) {
      console.error('Error ao enviar arquivo.', error);

      setData((state) => {
        state.filters.attachments.data = file.fileList.map((f) => {
          if (f.uid === file.file.uid) {
            return { ...f, status: 'error' };
          }
          return f;
        });
      });
    }
  },
  clearAttachmentFile: () => {
    const { setData } = get();
    setData((state) => {
      state.filters.attachments.data = [];
      state.filters.attachments.info = initialStateFilters.attachments.info;
    });
  },
  createServiceRequest: async () => {
    const { setData } = get();

    setData((state) => {
      state.filters.attachments.isLoading = true;
    });

    const email = get().filters.email.data;

    const periodDate =
      get().formFilterInstance?.getFieldValue?.('periodDate') ?? [];

    const autoCategoryAccountSelected =
      get().formFilterInstance?.getFieldValue?.('autoCategoryId') ?? null;

    const selectedCategoryName = get().filters.autoCategories.data.find(
      (autoCategory) => autoCategory.account === autoCategoryAccountSelected,
    )?.name;

    const description =
      get().formFilterInstance?.getFieldValue?.('description');

    const completeName =
      get().formFilterInstance?.getFieldValue?.('completeName');

    const initialPeriod =
      periodDate.length > 0 ? periodDate[0].format('DD-MM-YYYY') : '';

    const finalPeriod =
      periodDate.length > 0 ? periodDate[1].format('DD-MM-YYYY') : '';

    const customerAccountCodeSelected =
      get().formFilterInstance?.getFieldValue?.('customerAccountCode') ?? null;

    const menuOrPage = get().formFilterInstance?.getFieldValue?.('menuOrPage');
    const { isFailure, isReports } = get().filters.autoCategories;

    try {
      const params: ICreateServiceRequestParams = {
        TChamado: {
          Email: email,
          Solicitacao: '0001',
          Assunto: selectedCategoryName as string,
          AutoCategoria: autoCategoryAccountSelected,
          Descricao: description,
          TicketNumber: '',
          Base64: get().filters.attachments.info.Base64,
          Name: get().filters.attachments.info.FileName,
        },
        TCampoExtra: {
          accountCode: customerAccountCodeSelected ?? '',
          menuOrPageFailure: isFailure ? menuOrPage : '',
          CompletName: isReports ? completeName : '',
          InicialPeriod: isReports ? initialPeriod : '',
          FinalPeriod: isReports ? finalPeriod : '',
        },
      };

      const { data } = await createServiceRequest(params);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
}));

export default useServiceRequestsStore;
