import type { AxiosResponse } from 'axios';
import type { FeatureFlag } from 'zustand/featureFlag/useFeatureFlagStore';
import { producerApi } from './config';

interface IParams {
  flag: string;
}

export interface IResponse extends AxiosResponse {
  data: boolean;
}

export async function getFeatureFlag(params: IParams): Promise<FeatureFlag> {
  const response: IResponse = await producerApi.get(
    `/feature-flag/${params.flag}`,
  );

  return {
    name: params.flag,
    isEnabled: response.data,
  };
}
