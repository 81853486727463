import { AxiosResponse } from 'axios';
import AutoCategory from 'model/ServiceRequests/AutoCategory';
import Category from 'model/ServiceRequests/Category';
import SubCategory from 'model/ServiceRequests/SubCategory';
import { apiSession } from './config';

export const checkIfDeskManagerUserIsValid = (email: string) =>
  apiSession.get<void, AxiosResponse<string | number>>(
    `desk-manager/check-if-user-exists`,
    { params: { email } },
  );

export type IFetchParams = {
  Pesquisa: string;
  Ativo: string;
  isProducer: boolean;
};

export const getServiceRequestsCategories = (params: IFetchParams) =>
  apiSession.get<void, AxiosResponse<Category[]>>(`desk-manager/categories`, {
    params,
  });

export const getServiceRequestsSubCategories = (params: IFetchParams) =>
  apiSession.get<void, AxiosResponse<SubCategory[]>>(
    `desk-manager/sub-categories`,
    {
      params,
    },
  );

export const getServiceRequestsAutoCategories = (params: IFetchParams) =>
  apiSession.get<void, AxiosResponse<AutoCategory[]>>(
    `desk-manager/auto-categories`,
    {
      params,
    },
  );

export type ISendAttachmentFileParams = {
  Base64: string;
  FileName: string;
  FileSize: string;
};

export type AttachmentFileResponse = {
  Chave: string;
};

export type IChamado = {
  Email: string;
  Solicitacao: string;
  Assunto: string;
  AutoCategoria: string;
  Descricao: string;
  TicketNumber: string;
  Base64: string;
  Name: string;
};

export type ICampoExtra = {
  accountCode: string;
  menuOrPageFailure: string;
  CompletName: string;
  InicialPeriod: string;
  FinalPeriod: string;
};

export type ICreateServiceRequestParams = {
  TChamado: IChamado;
  TCampoExtra: ICampoExtra;
};

export type IServiceRequestResponse = string | { erro: string };

export const createServiceRequest = (params: ICreateServiceRequestParams) =>
  apiSession.post<void, AxiosResponse<IServiceRequestResponse>>(
    `desk-manager/create-ticket`,
    params,
  );
