import { useEffect, useState } from 'react';
import { useFeatureFlagStore } from 'zustand/featureFlag/useFeatureFlagStore';

export default function useRefreshFeatureFlags() {
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const refreshFeatureFlags = useFeatureFlagStore(
    (state) => state.refreshFeatureFlags,
  );

  useEffect(() => {
    if (!hasRefreshed) {
      refreshFeatureFlags();
      setHasRefreshed(true);
    }
  }, [refreshFeatureFlags, hasRefreshed]);
}
