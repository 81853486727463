import React, { useEffect } from 'react';

import CheckIndisponibility from '@core/application/useCases/indisponibility/checkIndisponibility';
import 'moment/locale/pt-br';
import useRefreshFeatureFlags from './hooks/useRefreshFeatureFlags';

import 'photoswipe/dist/default-skin/default-skin.css';
import 'photoswipe/dist/photoswipe.css';

import Routes from './routes';

function App(): React.ReactElement {
  useRefreshFeatureFlags();

  useEffect(() => {
    const checkIndisponibility = new CheckIndisponibility();
    checkIndisponibility.execute().then(() => {
      location.href = `${process.env.REACT_APP_SSO_URL}/maintenance` as string;
    });
  }, []);

  return <Routes />;
}

export default App;
