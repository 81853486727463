import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button as ButtonAntd, ButtonProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 24px 40px;
`;

export const TextAreaDescription = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 150px !important;
`;

export const Button = styled(ButtonAntd)<ButtonProps>`
  width: 100%;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 912px;
  height: 328px;
  margin: 0 auto;
`;

export const Icon = styled(ExclamationCircleOutlined)`
  font-size: 48px;
  color: #ff4d4f;
  margin-bottom: 12px;
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: black;
`;

export const WarningText = styled.p`
  color: red;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
`;
