/* eslint-disable no-nested-ternary */
import {
  AppstoreOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  DownOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Popover,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import DeliveryDate from 'components/UI/DeliveryDate';
import ShowOrHideValues from 'components/UI/ShowOrHideValues';
import jwtDecode from 'jwt-decode';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BuyIntention from 'screens/BuyIntention';
import MyOrderIntentionEdit from 'screens/MyOrderIntentions/myIntentionEdit';
import { showAlertError } from 'services/alertService';
import {
  getAuthorizationToken,
  getTermProductList,
  setBillingDate,
  setCustomerIdSelected,
  setDeliveryDate,
  setLogout,
  setPageProductList,
  setPageState,
  setTermProductList,
} from 'services/config';
import {
  useGetBalanceQuery,
  useGetSummaryCartQuery,
} from 'services/ecommerceApi';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import {
  buyIntenteionSelectors,
  cartSelectors,
  myOrderIntentionEditSelectors,
  offerFiltersSelectors,
  sessionSelectors,
} from 'store/state/selectors';
import {
  balanceActions,
  buyIntentionActions,
  cartActions,
  errorActions,
  offerFiltersActions,
  sessionActions,
} from 'store/state/slices';
import { dateUtils } from 'utils/date';
import { notSpecialCharacters } from 'utils/util';
import {
  FeatureFlags,
  useFeatureFlagStore,
} from 'zustand/featureFlag/useFeatureFlagStore';
import { HeaderEnum } from '../../../../enums/header.enum';
import * as S from './styles';

function Header() {
  const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';
  const token = getAuthorizationToken();
  const disabledQRCode = false;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ssoUrl = process.env.REACT_APP_SSO_LOGIN_URL ?? '';
  const { Option } = Select;
  const { Text } = Typography;

  const [showBalance, setShowBalance] = useState(false);
  const [error, setError] = useState<{ status: string; message: string }>();
  const [timeLeft, setTimeLeft] = useState(60);

  const {
    name: userName,
    customers,
    deliveryDate,
    billingDate,
    producerUrl,
    email,
  } = useSelector(sessionSelectors.getRoot);
  const showBuyIntentionModal = useSelector(
    buyIntenteionSelectors.getShowIntentionModal,
  );
  const showMyOrderIntentionEditModal = useSelector(
    myOrderIntentionEditSelectors.getShowIntentionModal,
  );
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const producerUrls = useSelector(sessionSelectors.getUrlsProducer);
  const totalVol = useSelector(cartSelectors.getVolTotal);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const filter = useSelector(offerFiltersSelectors.getOfferFilters);
  const [customerSelected, setCustomerSelected] = useState(
    customers.find((customer) => customer.id === selectedCustomerId),
  );
  const [urlSelected, setUrlSelected] = useState<string>('');

  const [searchValue, setSearchValue] = useState(
    getTermProductList() !== '' ? getTermProductList() : filter.name,
  );

  const totalVolItems = useSelector(cartSelectors.getTotalVolItems);
  const totalLkpItems = useSelector(cartSelectors.getTotalLkpItems);
  const volExpirationDate = useSelector(cartSelectors.getVolExpiration);
  const lkpExpirationDate = useSelector(cartSelectors.getLkpExpiration);

  const isFeatureFlagEnabled = useFeatureFlagStore(
    (state) => state.isFeatureFlagEnabled,
  );

  const isDeskManagerServiceRequestsEnabled = isFeatureFlagEnabled(
    FeatureFlags.DeskManagerServiceRequestsOnEcommerce,
  );

  useEffect(() => {
    if (token) {
      const { exp }: any = jwtDecode(token);
      const expireDate = moment(exp * 1000);
      if (expireDate) {
        const now = moment().format('DD/MM/YYYY HH:mm:ss');
        const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(now, 'DD/MM/YYYY HH:mm:ss'),
        );
        const timeLeftInMin = Math.floor(timeLeftInMs / 60000);
        setTimeLeft(timeLeftInMin);
      }
    }
  }, [token]);

  useEffect(() => {
    const timer = setInterval(() => setMinutesRemaining(), 60000);
    dispatch(sessionActions.setTimeLeft(timeLeft));
    if (timeLeft <= 1) {
      dispatch(errorActions.throw(UNAUTHORIZED_REQUEST));
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const setMinutesRemaining = () => {
    if (getAuthorizationToken()) {
      const { exp }: any = jwtDecode(getAuthorizationToken());
      const expireDate = moment(exp * 1000);
      if (expireDate) {
        const now = moment().format('DD/MM/YYYY HH:mm:ss');
        const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(now, 'DD/MM/YYYY HH:mm:ss'),
        );
        const timeLeftInMin = Math.floor(timeLeftInMs / 60000);
        setTimeLeft(timeLeftInMin);
      }
    }
  };

  const expirationCart = useMemo(() => {
    if (totalVolItems > 0 && totalLkpItems === 0) {
      return moment(new Date()).diff(moment(new Date(volExpirationDate))) >= 0;
    }

    if (totalVolItems === 0 && totalLkpItems > 0) {
      return moment(new Date()).diff(moment(new Date(lkpExpirationDate))) >= 0;
    }

    if (totalVolItems > 0 && totalLkpItems > 0) {
      return (
        moment(new Date()).diff(moment(new Date(volExpirationDate))) >= 0 &&
        moment(new Date()).diff(moment(new Date(lkpExpirationDate))) >= 0
      );
    }

    return false;
  }, [lkpExpirationDate, totalLkpItems, totalVolItems, volExpirationDate]);

  useEffect(() => {
    setSearchValue((value) => {
      const newValue = filter.name !== value ? filter.name : value;
      setTermProductList(newValue || '');
      return newValue;
    });
  }, [filter.name]);

  useEffect(() => {
    setUrlSelected(producerUrl);
  }, [producerUrl]);

  const logout = useCallback(() => {
    setLogout();
    dispatch(sessionActions.logout());
    navigate('/logout');
  }, [dispatch, navigate]);

  const toggleBalance = useCallback(() => {
    setShowBalance((value) => !value);
  }, []);

  const { data: summaryCart, isError: errorRequestSummary } =
    useGetSummaryCartQuery(
      {
        customerId: selectedCustomerId,
      },
      { skip: !isAuthenticated || !selectedCustomerId },
    );

  const {
    data: balance,
    isLoading: isLoadingBalance,
    isFetching: isFetchingBalance,
    isError: err,
  } = useGetBalanceQuery(customerSelected?.accountCode || '', {
    skip: !customerSelected?.accountCode,
  });

  useEffect(() => {
    if (selectedCustomerId) {
      setCustomerSelected(
        customers.find((customer) => customer.id === selectedCustomerId),
      );
      const customerData = customers.find(
        (customer) => customer.id === selectedCustomerId,
      );

      dispatch(buyIntentionActions.setCustomer(customerData));
      dispatch(sessionActions.setSelectedCustomerFilter(selectedCustomerId));
    }
    const customerData = customers.find(
      (customer) => customer.id === selectedCustomerId,
    );
    dispatch(sessionActions.setCustomer(customerData));
  }, [selectedCustomerId, customers]);

  useEffect(() => {
    if (summaryCart && summaryCart.totalItems > 0 && !errorRequestSummary) {
      dispatch(cartActions.setSummaryCart(summaryCart));
    }
    if (summaryCart && totalVol > 0) {
      dispatch(
        sessionActions.setBillingDate(
          dateUtils.createDateWithTimeZone(summaryCart.billingDate),
        ),
      );
      dispatch(
        sessionActions.setDeliveryDate(
          dateUtils.createDateWithTimeZone(summaryCart.deliveryDate),
        ),
      );
    }
  }, [dispatch, summaryCart, totalVol, errorRequestSummary]);

  useEffect(() => {
    if (errorRequestSummary) {
      dispatch(
        cartActions.setSummaryCart({
          lkpOfferId: 0,
          volOfferId: 0,
          customerId: Number.NaN,
          volTotal: 0,
          lkpTotal: 0,
          totalItems: 0,
          user: '',
          deliveryDate: '',
          billingDate: '',
          volExpiration: '',
          lkpExpiration: '',
          totalLkpItems: 0,
          totalVolItems: 0,
          lKpCreationDate: '',
          volCreationDate: '',
        }),
      );
    }
  }, [dispatch, errorRequestSummary]);

  useEffect(() => {
    if (err) {
      const errorData: any = { ...error };
      showAlertError(
        dispatch,
        `Não foi possivel recuperar o saldo, favor entrar em contato com TI ${
          JSON.stringify(errorData) ?? ''
        }`,
      );
    }
    dispatch(balanceActions.setBalance(balance ?? 0));
  }, [err, dispatch, balance]);

  const setSelectedCustomer = useCallback(
    (value) => {
      dispatch(sessionActions.setSelectedCustomer(value));
      dispatch(sessionActions.setSelectedCustomerFilter(value));

      const customerData = customers.find((customer) => customer.id === value);
      dispatch(buyIntentionActions.setCustomer(customerData));
      setCustomerIdSelected(value);
    },
    [dispatch, customers],
  );

  const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(
    null,
  );
  const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null);
  const [showDatePickerDelivery, setShowDatePickerDelivery] =
    useState<boolean>(true);
  const [showDatePickerBilling, setShowDatePickerBilling] =
    useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleOnChangeDelivery = useCallback((value: Moment | null) => {
    setPageProductList(1);
    setBillingDateValue(value);
    setBillingDate(value?.format('YYYY-MM-DD') ?? '');
    setDeliveryDateValue(value);
    setDeliveryDate(value?.format('YYYY-MM-DD') ?? '');
    if (value) {
      setShowDatePickerBilling(true);
    }
  }, []);

  const handleOnChangeBilling = useCallback((value: Moment | null) => {
    setPageProductList(1);
    setBillingDateValue(value);
    setBillingDate(value?.format('YYYY-MM-DD') ?? '');
  }, []);

  const handleOnOpenChangeDelivery = useCallback(
    (event: boolean) => {
      setShowDatePickerDelivery(event);

      if (deliveryDateValue !== null) {
        setShowDatePickerBilling(true);
      }
    },
    [deliveryDateValue],
  );
  const handleOnOpenChangeBilling = useCallback((event: boolean) => {
    setShowDatePickerBilling(event);
  }, []);

  const disableDatesDelivery = useCallback(
    (value: Moment) => value.isBefore(moment().subtract(1, 'days')),
    [],
  );

  const disableDatesBilling = useCallback(
    (value: Moment) => {
      if (deliveryDateValue !== null) {
        const initalDay = moment(deliveryDateValue);
        const lastDay = moment(deliveryDateValue).add(1, 'days');

        return value.isAfter(lastDay) || value.isBefore(initalDay);
      }
      return false;
    },
    [deliveryDateValue],
  );

  const filterProductsByName = useCallback(
    (term) => {
      dispatch(offerFiltersActions.filter({ ...filter, name: term }));
      setPageProductList(1);
      setTermProductList(term);
      navigate(`/${producerUrl}/products/`);
    },
    [dispatch, navigate, filter, producerUrl],
  );

  const handleOnVisibleChange = useCallback(
    (event: boolean) => {
      setShowPopover(event);

      if (event === true) {
        if (deliveryDate) {
          setDeliveryDateValue(moment(deliveryDate));
        }
        if (billingDate) {
          setBillingDateValue(moment(billingDate));
        }
        setTimeout(() => {
          setShowDatePickerDelivery(event);
        }, 0);
      } else {
        setShowDatePickerDelivery(event);
      }
    },
    [billingDate, deliveryDate],
  );

  const handleChangeUrlSelected = (value: string) => {
    setUrlSelected(value);
    navigate(`${location.pathname.replace(producerUrl, value)}`);
  };

  const filterProductsByDeliveryDate = useCallback(() => {
    const deliveryDates = {
      billingDate: billingDateValue?.format('YYYY-MM-DD') ?? '',
      deliveryDate: deliveryDateValue?.format('YYYY-MM-DD') ?? '',
    };
    dispatch(sessionActions.setDates(deliveryDates));
    handleOnVisibleChange(false);
    dispatch(
      offerFiltersActions.filter({
        ...filter,
        deliveryDate: deliveryDates.deliveryDate,
        businessDate: deliveryDates.billingDate,
      }),
    );
    navigate(
      `/${producerUrl}/products${
        filter.categories ? `/${filter.categories}` : ''
      }`,
    );
    setShowPopover(false);
  }, [
    dispatch,
    billingDateValue,
    deliveryDateValue,
    handleOnVisibleChange,
    filter,
    navigate,
    producerUrl,
  ]);

  const formatCustomerName = (customerName: string) => {
    if (customerName.length <= 22) return customerName;

    return `${customerName.substring(0, 22)}...`;
  };

  const handleClickMyOrders = () => {
    // if (customers.length === 0 && !customers) {
    //   dispatch(errorActions.throw(UNAUTHORIZED_REQUEST));
    //   return;
    // }
    navigate(`/${producerUrl}/my-orders`);
  };

  const handleClickMyOrderIntentions = () => {
    if (timeLeft <= 25) {
      dispatch(sessionActions.setRefreshToken(true));
    }
    navigate(`/${producerUrl}/my-order-intentions`);
  };

  const handleClickServiceRequest = () => {
    if (!isDeskManagerServiceRequestsEnabled) {
      window.open(HeaderEnum.LINKSUPPORT);
    } else {
      navigate(`/${producerUrl}/service-request`);
    }
  };

  const handleClickBuyIntention = () => {
    // if (customers.length === 0 && !customers) {
    //   dispatch(errorActions.throw(UNAUTHORIZED_REQUEST));
    //   return;
    // }
    dispatch(buyIntentionActions.setShowIntentionModal(true));
    dispatch(BuyIntentionCalls.fetchProducers());
  };

  const handleClickApps = () => {
    window.open(process.env.REACT_APP_SSO_APPS);
  };

  const content = useMemo(
    () => (
      <DeliveryDate
        initialDatePicker={{
          disabled: totalVol > 0,
          disabledDate: disableDatesDelivery,
          value: deliveryDateValue,
          open: showDatePickerDelivery && showPopover,
          onOpenChange: handleOnOpenChangeDelivery,
          onChange: handleOnChangeDelivery,
        }}
        endDatePicker={{
          disabled: deliveryDateValue === null || totalVol > 0,
          disabledDate: disableDatesBilling,
          value: billingDateValue,
          open: showDatePickerBilling && showPopover && !showDatePickerDelivery,
          onOpenChange: handleOnOpenChangeBilling,
          onChange: handleOnChangeBilling,
        }}
        onConfirmation={filterProductsByDeliveryDate}
        producerUnavailableDates={[]}
      />
    ),
    [
      billingDateValue,
      deliveryDateValue,
      disableDatesBilling,
      disableDatesDelivery,
      filterProductsByDeliveryDate,
      handleOnOpenChangeDelivery,
      showDatePickerBilling,
      showDatePickerDelivery,
      showPopover,
      totalVol,
    ],
  );

  return (
    <S.Root
      className={
        isAuthenticated && !isProducerAuthenticated ? 'is-authenticated' : ''
      }
    >
      <S.ImageAndCustomersDiv>
        <S.LogoDiv>
          <S.Logo
            onClick={() => {
              navigate(`/${producerUrl}/`);
              setPageState(`/${producerUrl}/`);
            }}
          />
        </S.LogoDiv>

        {isAuthenticated && isProducerAuthenticated && (
          <S.SelectUrls>
            <Select
              value={urlSelected}
              onChange={handleChangeUrlSelected}
              style={{ width: 300 }}
              disabled={producerUrls?.length < 2}
              showSearch
              optionFilterProp="children"
              bordered={false}
            >
              {producerUrls?.length > 1 && (
                <Option key="veiling" value="veiling">
                  Veiling
                </Option>
              )}
              {producerUrls.map((url) => (
                <Option key={url.url} value={url.url}>
                  {url.url}
                </Option>
              ))}
            </Select>
          </S.SelectUrls>
        )}

        {isAuthenticated && !isProducerAuthenticated && (
          <S.ClientsBalanceContainer>
            {customers?.length && (
              <Select
                style={{ width: 300 }}
                showSearch
                optionFilterProp="children"
                bordered={false}
                value={selectedCustomerId}
                onChange={setSelectedCustomer}
              >
                {customers.map(({ id, accountCode, name }) => (
                  <Option
                    key={`${id}-key-customer`}
                    value={id}
                    title={`${accountCode} - ${name}`}
                  >
                    {`${accountCode} - ${formatCustomerName(name)}`}
                  </Option>
                ))}
              </Select>
            )}
            <S.BalanceAmoutContainer>
              <ShowOrHideValues
                showValue={showBalance}
                setShowValue={toggleBalance}
              />
              {showBalance ? (
                isLoadingBalance || isFetchingBalance ? (
                  <S.LoadingSpin spinning size="small" />
                ) : (
                  <Text>
                    {t('common.monetary.symbolValue', {
                      value: balance || '',
                    })}
                  </Text>
                )
              ) : (
                <Text>
                  {t('common.monetary.symbol')}••••••••••••
                  {/* {timeLeft} */}
                </Text>
              )}
            </S.BalanceAmoutContainer>
          </S.ClientsBalanceContainer>
        )}
      </S.ImageAndCustomersDiv>
      <div>
        <S.SearchDiv>
          <Input
            style={{ width: '100%', minWidth: '250px' }}
            placeholder={t('header.search')}
            value={searchValue}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                setTermProductList(e.target.value);
                dispatch(
                  offerFiltersActions.filter({
                    ...filter,
                    name: e.target.value,
                  }),
                );
              }
              setSearchValue(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                filterProductsByName(searchValue);
              } else {
                notSpecialCharacters(event);
              }
            }}
          />
          <Tooltip title="Buscar">
            <Button
              onClick={() => filterProductsByName(searchValue)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
        </S.SearchDiv>
      </div>
      <S.DivGroupDataAndButtons>
        {isAuthenticated && (
          <S.DeliveryBillingDiv>
            <Popover
              visible={showPopover}
              placement="bottom"
              content={content}
              trigger="click"
              onVisibleChange={handleOnVisibleChange}
            >
              <S.PopoverTextLink>
                <CalendarOutlined />
                <Text
                  onClick={() =>
                    setShowDatePickerDelivery(!showDatePickerDelivery)
                  }
                >
                  Data de entrega (ENP)
                </Text>
              </S.PopoverTextLink>
            </Popover>
          </S.DeliveryBillingDiv>
        )}
        <Space size="large">
          {isAuthenticated && (
            <Dropdown
              arrow
              overlay={
                <Menu>
                  {!isProducerAuthenticated && (
                    <Menu.Item
                      key="drop-down-actions-1"
                      onClick={handleClickMyOrders}
                    >
                      {t('header.myOrders')}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="drop-down-actions-5"
                    onClick={handleClickMyOrderIntentions}
                  >
                    {t('header.myOrderIntentions')}
                  </Menu.Item>
                  <Menu.Item
                    key="drop-down-actions-4"
                    onClick={handleClickBuyIntention}
                  >
                    Cadastrar Intenção de Compra +
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={handleClickServiceRequest}
                    key="drop-down-actions-2"
                  >
                    Abertura de Chamados
                  </Menu.Item>
                  <Menu.Item onClick={() => logout()} key="drop-down-actions-3">
                    {t('header.logout')} <LogoutOutlined />
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <S.UserName>
                {userName}
                <DownOutlined />
              </S.UserName>
            </Dropdown>
          )}
          {!isAuthenticated && (
            <S.SignIn
              onClick={() => {
                window.location.href = ssoUrl;
                return null;
              }}
            >
              Entrar
            </S.SignIn>
          )}
          {isAuthenticated && (
            <>
              {!isProducerAuthenticated && (
                <>
                  <Tooltip title={t('header.contacts')}>
                    <ContactsOutlined
                      onClick={() =>
                        window.open(HeaderEnum.LINKBUSINESSCONTACTS, '_blank')
                      }
                      style={{ fontSize: '16px', color: '#262626' }}
                    />
                  </Tooltip>
                  <Tooltip title="Carrinho">
                    <Badge
                      size="small"
                      count={
                        expirationCart ? (
                          <ClockCircleOutlined />
                        ) : (
                          summaryCart?.totalItems || 0
                        )
                      }
                      offset={[2, -2]}
                      color="#FFC000"
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() =>
                          navigate(`/${producerUrl}/shopping-cart`)
                        }
                        icon={
                          <ShoppingCartOutlined style={{ fontSize: '16px' }} />
                        }
                      />
                    </Badge>
                  </Tooltip>
                </>
              )}
              <Tooltip title={t('header.apps')}>
                <Button
                  type="primary"
                  shape="circle"
                  onClick={handleClickApps}
                  icon={<AppstoreOutlined style={{ fontSize: '16px' }} />}
                />
              </Tooltip>

              {disabledQRCode &&
                isAuthenticated &&
                isMobile &&
                !isProducerAuthenticated && (
                  <Tooltip title={t('header.qrcode')}>
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => navigate(`/${producerUrl}/read-qrcode`)}
                      icon={<QrcodeOutlined style={{ fontSize: '16px' }} />}
                    />
                  </Tooltip>
                )}
            </>
          )}
        </Space>
      </S.DivGroupDataAndButtons>
      {showBuyIntentionModal ? <BuyIntention /> : null}
      {showMyOrderIntentionEditModal ? <MyOrderIntentionEdit /> : null}
    </S.Root>
  );
}
export default React.memo(Header);
