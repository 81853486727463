import { LeftOutlined } from '@ant-design/icons';
import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderLine1, HeaderLine2 } from 'screens/MyOffers/styles';
import { sessionSelectors } from 'store/state/selectors';
import {
  ContinueShoppingButton,
  Divider,
  HeaderLine1GroupLeft,
  Legend,
  Root,
  Title,
} from './ServiceRequest.styled';
import ServiceRequestFilter from './ServiceRequestFilter';

export default function ServiceRequest() {
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const navigate = useNavigate();

  return (
    <Screen
      content={
        <Root>
          <Divider />

          <Header>
            <HeaderLine1>
              <HeaderLine1GroupLeft>
                <Title>Abertura de Chamados</Title>
                <Legend>
                  Solução integrada para abertura e acompanhamento de chamados
                  na plataforma
                </Legend>
              </HeaderLine1GroupLeft>
              <Breadcrumb levels={[{ description: 'Abertura de Chamados' }]} />
            </HeaderLine1>
            <HeaderLine2>
              <ContinueShoppingButton
                icon={<LeftOutlined />}
                onClick={() => navigate(`/${producerUrl}/products`)}
              >
                Continuar comprando
              </ContinueShoppingButton>
            </HeaderLine2>
          </Header>
          <ServiceRequestFilter />
        </Root>
      }
    />
  );
}
