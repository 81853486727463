import { InboxOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import AutoCategory from 'model/ServiceRequests/AutoCategory';
import Category from 'model/ServiceRequests/Category';
import SubCategory from 'model/ServiceRequests/SubCategory';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'store/state/selectors';
import useServiceRequestsStore, {
  IFormFilter,
} from 'zustand/serviceRequests/useServiceRequestsStore';
import * as S from './ServiceRequestFilter.styled';

export default function ServiceRequestFilter() {
  const [formFilter] = useForm<IFormFilter>();
  const { customers, email } = useSelector(sessionSelectors.getRoot);

  const {
    clearAttachmentFile,
    createServiceRequest,
    setFormInstance,
    getSubCategories,
    getAutoCategories,
    sendAttachmentFile,
    isFiltersSuccessful,
    filters,
    setData,
    validateEmailAndLoadFilters,
  } = useServiceRequestsStore();

  useEffect(() => {
    const instance = {
      validateFields: formFilter.validateFields,
      getFieldValue: formFilter.getFieldValue,
      getFieldsValue: formFilter.getFieldsValue,
      setFieldsValue: formFilter.setFieldsValue,
    } as Partial<FormInstance<IFormFilter>>;

    setFormInstance(instance);
  }, [formFilter, setFormInstance]);

  // console.log('filters email valid >>>>>>>>', filters.email.isValid);

  useEffect(() => {
    if (email) {
      validateEmailAndLoadFilters(email);
    }
  }, [validateEmailAndLoadFilters, email]);

  const onSubmit = async () => {
    try {
      await formFilter.validateFields();
      const response = await createServiceRequest();

      if (response && typeof response === 'object' && 'erro' in response) {
        const errorMessage = response.erro;
        message.error(errorMessage);
        return;
      }
      formFilter.resetFields();
      clearAttachmentFile();
      message.success(
        `Solicitação criada com sucesso! Número do chamado: ${response}`,
      );
    } catch (error) {
      message.error(
        'Alguns campos de filtro são obrigatórios. Por favor, preencha-os e tente novamente.',
      );
    }
  };

  const handleCategoryOnClear = () => {
    formFilter.setFieldsValue({
      categoryId: undefined,
    });
  };

  const handleCategoryOnChange = () => {
    formFilter.setFieldsValue({
      subCategoryId: undefined,
      autoCategoryId: undefined,
    });

    getSubCategories();
    getAutoCategories();
  };

  const handleSubgroupOnClear = () => {
    formFilter.setFieldsValue({
      subCategoryId: undefined,
    });
  };

  const handleSubgroupOnChange = () => {
    formFilter.setFieldsValue({
      autoCategoryId: undefined,
      menuOrPage: undefined,
      completeName: undefined,
      periodDate: undefined,
    });

    getAutoCategories();
  };

  const handleAutoCategoryOnClear = () => {
    formFilter.setFieldsValue({
      autoCategoryId: undefined,
      menuOrPage: undefined,
      completeName: undefined,
      periodDate: undefined,
    });
  };

  const handleAutoCategoryOnChange = (autoCategoryId: number) => {
    const autoCategoryName = filters.autoCategories.data.find(
      (item: AutoCategory) => item.account === autoCategoryId,
    )?.name;
    const failureAutoCategory = autoCategoryName?.includes('Falha') ?? false;
    const reportsAutoCategory =
      autoCategoryName?.includes('Relatório') ?? false;
    setData((state) => {
      state.filters.autoCategories.isFailure = failureAutoCategory;
      state.filters.autoCategories.isReports = reportsAutoCategory;
    });
  };

  if (!filters.email.isValid) {
    return (
      <S.ErrorContainer>
        <S.Icon data-testid="error-icon" />
        <S.Title>
          Ocorreu um erro inesperado! Não foi possível localizar sua conta no
          Serviço de Suporte. Atualize a página e tente novamente, caso persista
          entre em contato com o TI Veiling.
        </S.Title>
      </S.ErrorContainer>
    );
  }

  return (
    <S.Container>
      <Form
        form={formFilter}
        initialValues={filters.formFilter}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Cliente"
              name="customerAccountCode"
              rules={[
                {
                  required: true,
                  message: 'Selecione corretamente uma chave de cliente',
                },
              ]}
            >
              <Select showSearch optionFilterProp="children">
                {customers.map((customer) => (
                  <Select.Option
                    key={customer.accountCode}
                    value={customer.accountCode}
                  >
                    {`${customer.accountCode} - ${customer.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Categoria"
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: 'Selecione corretamente uma categoria',
                },
              ]}
            >
              <Select
                placeholder="Selecione uma categoria"
                showSearch
                showArrow
                allowClear
                loading={filters.categories.isLoading}
                disabled={filters.categories.isLoading}
                optionFilterProp="children"
                optionLabelProp="label"
                onClear={handleCategoryOnClear}
                onChange={handleCategoryOnChange}
              >
                {filters.categories.data.map((item: Category) => (
                  <Select.Option
                    key={item.account}
                    value={item.account}
                    label={item.name}
                    title={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Sub Categoria"
              name="subCategoryId"
              rules={[
                {
                  required: true,
                  message: 'Selecione corretamente uma sub categoria',
                },
              ]}
            >
              <Select
                placeholder="Selecione uma sub categoria"
                showSearch
                showArrow
                allowClear
                loading={filters.subCategories.isLoading}
                disabled={filters.subCategories.isLoading}
                optionFilterProp="children"
                optionLabelProp="label"
                onClear={handleSubgroupOnClear}
                onChange={handleSubgroupOnChange}
              >
                {filters.subCategories.data.map((item: SubCategory) => (
                  <Select.Option
                    key={item.account}
                    value={item.account}
                    label={item.name}
                    title={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Assunto"
              name="autoCategoryId"
              rules={[
                {
                  required: true,
                  message: 'Selecione corretamente um assunto de solicitação',
                },
              ]}
            >
              <Select
                placeholder="Selecione um assunto de solicitação"
                showSearch
                showArrow
                allowClear
                loading={filters.autoCategories.isLoading}
                disabled={filters.autoCategories.isLoading}
                optionFilterProp="children"
                optionLabelProp="label"
                onClear={handleAutoCategoryOnClear}
                onChange={handleAutoCategoryOnChange}
              >
                {filters.autoCategories.data.map((item: AutoCategory) => (
                  <Select.Option
                    key={item.account}
                    value={item.account}
                    label={item.name}
                    title={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {filters.autoCategories.isFailure ? (
            <Col lg={24}>
              <Form.Item label="Informe o menu ou página" name="menuOrPage">
                <Input />
              </Form.Item>
            </Col>
          ) : null}
          {filters.autoCategories.isReports ? (
            <>
              <Col lg={24}>
                <Form.Item
                  label="Informe seu nome completo"
                  name="completeName"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  label="Informe o período inicial e final"
                  name="periodDate"
                >
                  <DatePicker.RangePicker
                    key="date-picker"
                    aria-label="Informe o período inicial e final"
                    allowClear
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
          <Col lg={24}>
            <Form.Item
              label="Descrição"
              name="description"
              rules={[{ required: true, message: 'Descreva seu incidente' }]}
            >
              <S.TextAreaDescription />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item>
              <>
                {filters.autoCategories.isFailure &&
                filters.attachments.data.length <= 0 ? (
                  <S.WarningText>
                    Toda solicitação de falha deve conter um anexo relacionado
                    ao problema, favor inserir e prosseguir com a abertura do
                    chamado
                  </S.WarningText>
                ) : null}
                <Upload.Dragger
                  accept=".png, .jpg, .jpeg .jfif"
                  name="attachment"
                  fileList={filters.attachments.data}
                  multiple={false}
                  beforeUpload={(file: RcFile) => {
                    const isLt4M = file.size / 1024 / 1024 < 4;
                    if (!isLt4M) {
                      message.error('O Arquivo deve ser menor que 4MB!');
                    }
                    return isLt4M;
                  }}
                  onChange={(info) => sendAttachmentFile(info)}
                  customRequest={(options: any) => {
                    const { onSuccess } = options;
                    onSuccess('Ok');
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: '#F78E1E' }} />
                  </p>
                  <p className="ant-upload-text">
                    Arraste seu arquivo ou clique para upload
                  </p>
                  <p className="ant-upload-hint">
                    Formatos de arquivo suportados: JPG / JPEG e PNG
                  </p>
                </Upload.Dragger>
              </>
            </Form.Item>
          </Col>
          <Col lg={24} xs={12}>
            <S.Button
              disabled={!isFiltersSuccessful || !filters.email.isValid}
              onClick={onSubmit}
              type="primary"
              htmlType="submit"
              aria-label="search"
              size="large"
            >
              Enviar
            </S.Button>
          </Col>
        </Row>
      </Form>
    </S.Container>
  );
}
